import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: 'G-TP9MPM31JF',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '2b36ce5a-8a3a-47a7-b586-e31d0d38d937',
  footerLogoId: '5f83571c-59e7-4aa3-956e-186fade14996',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Jeux de Fille',
  titleTemplate: 'or-site-jeux-de-fille | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '1e179b74-6740-46fc-b4e4-a180477e6826',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      strategy: 'afterInteractive',
      charset: 'UTF-8',
      'data-domain-script': '1e179b74-6740-46fc-b4e4-a180477e6826'
    },
    {
      id: 'ot-callback-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id=G-TP9MPM31JF`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-TP9MPM31JF', {
              page_path: window.location.pathname,
            });
          `
      }
    },
  ],
  links: [
  ],
};